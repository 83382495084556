<template>
    <main>
        <div id="login-form--wrapper">
            <div class="column-1">
                <div class="ui basic segment container">
                    <router-link :to="{ name: 'Home' }"><img class="logo" src="~@/assets/images/logo/1991spwg.png"/></router-link>
                    <form class="ui underlined form" :class="{loading: submitting}" action="javascript:void(0);" @submit.prevent="submit">
                        <div class="ui hidden section divider"></div>
                        <h1 class="ui poppins weight-400 header" style="font-size: 3rem;">Forgot Password</h1>
                        <p class="ui grey text">Enter your email address and we will get you a temporary password.</p>
                        <div class="ui hidden section divider"></div>
                        <div class="field">
                            <label class="ui grey text">Email</label>
                            <input type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$" v-model.trim="form.email" v-lowercase required/>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="ui right aligned text">
                            <button type="submit" class="ui black button">Send Me</button>
                        </div>
                        <div class="ui hidden section divider"></div>
                        <div class="ui hidden section divider"></div>
                    </form>
                </div>
            </div>
            <div class="column-2">

            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: ""
            },
            submitting: false
        };
    },
    methods: {
        submit() {
            this.submitting = true;
            this.$http.post("users/temp-password", this.form).finally(() => {
                this.$toasted.show("Please check your email for reset password instruction.", {
                    duration: 3000,
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            this.$router.push({
                                name: "Home"
                            });

                            toast.goAway(0);
                        }
                    },
                    onComplete: () => {
                        this.$router.push({
                            name: "Home"
                        });
                    }
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
main {
    #login-form--wrapper {
        display: flex;
        align-items: stretch;

        .column-1 {
            background: #fff;
            width: 38.2%;
            flex: 0 0 38.2%;
            display: flex;
            align-items: center;
            justify-content: center;
             overflow: hidden;

            .ui.form {
                align-self: stretch;
                animation: animation-slideUp ease-out 300ms;
            }
        }

        .column-2 {
            width: 61.8%;
            flex: 0 0 61.8%;
            background: #fff url('~@/assets/images/background/login.min.jpg') no-repeat center center/cover;
        }

        .logo {
            max-width: 90%;
            width: 200px;
        }
    }

    @media only screen and (min-width: 1025px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        #login-form--wrapper {
            max-width: 1024px;
            box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
        }
    }

    @media only screen and (max-width: 1024px) {
        #login-form--wrapper {
            min-height: 100vh;
        }
    }

    @media only screen and (max-width: 1023px) {
        #login-form--wrapper {
            position: relative;
            align-items: center;
            justify-content: center;

            .logo {
                margin-top: -3rem;
            }

            .column-1 {
                flex: 0 0 auto;
                width: 500px;
                max-width: 100%;
                background: none;
            }

            .column-2 {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        background: url('~@/assets/images/background/login-main.jpg') no-repeat center center/cover,
                    linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.9) 100%);
        background-blend-mode: saturation;

        #login-form--wrapper {
            .column-1 {
                padding: 3rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        #login-form--wrapper {
            .column-1 {
                padding-top: 3rem;
                padding-bottom: 3rem;

                > .ui.segment.container {
                    width: 100% !important;
                }
            }
        }
    }
}
</style>